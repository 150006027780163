@import "svg-dimensions";

@mixin desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin mouse {
  @media screen and (any-pointer: fine) {
    @content;
  }
}

@mixin touch {
  @media screen and (any-pointer: coarse) {
    @content;
  }
}

@font-face {
  font-family: "Futura Mono";
  font-weight: bold;
  src: url("../fonts/Futura Mono.woff") format("woff"),
       url("../fonts/Futura Mono.woff2") format("woff2");
}

@font-face {
  font-family: "Lausanne";
  src: url("../fonts/Lausanne-Regular.woff") format("woff"),
       url("../fonts/Lausanne-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lausanne";
  font-style: italic;
  src: url("../fonts/Lausanne-Italic.woff") format("woff"),
       url("../fonts/Lausanne-Italic.woff2") format("woff2");
}

$bg: hsl(44,15%,91%);
$blue: hsl(240,100%,40%);
$purple: hsl(260,100%,40%);
$color: hsl(360,2%,10%);
$padding: 50px;
$mobile-padding: 20px;
$body: "Lausanne", sans-serif;
$headline: "Futura Mono", sans-serif;
$bottom-space: 140px;
$mobile-bottom-space: 40px;

$menu-horizontal-padding: 20px;
$mobile-menu-horizontal-padding: 10px;
$mobile-header-height: 82px;

$cell-size: 60px;
$cell-image-width: $cell-size;
$cell-image-height: $cell-size;
$cell-hover: hsla(44,13%,80%,.2);

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5;
}

body {
  background: $bg;
  font-family: $body;
  font-size: 16px;
  line-height: 1.35;
  color: $color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-family: $headline;
  font-size: 34px;
  line-height: 1.1;
  
  @include phone {
    font-size: 6vw;
  }
}

p {
  margin-bottom: 1em;
}

a {
  color: inherit;
  text-decoration: none;
}

blockquote {
  @extend h1;
  color: inherit;
}

.container {
  padding: 0 $padding;
  
  @include phone {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}

.image-ratio {
  position: relative;
  
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

svg {
  fill: currentColor;
  vertical-align: middle;
}

.header {
  padding: 25px 0;
  
  .container {
    display: flex;
    position: relative;
  }
}

.logo {
  margin-left: -$menu-horizontal-padding;
  
  @include phone {
    margin-left: -$mobile-menu-horizontal-padding;
  }
}

.menu {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -$menu-horizontal-padding;
  display: flex;
  
  @include phone {
    margin-left: -$mobile-menu-horizontal-padding;
  }
}

.menu-link {
  display: block;
  padding: 0 #{$menu-horizontal-padding};
  text-decoration: none;
  @extend %clickable;
  -webkit-tap-highlight-color: black;
  
  .header & {
    font-family: $headline;
    font-size: 2.4em;
    line-height: 1;
  }
  
  &:hover,
  &.is-active {
    font-style: italic;
  }

  @include phone {
    padding-left: $mobile-menu-horizontal-padding;
    padding-right: $mobile-menu-horizontal-padding;
  }

  & + & {
    padding-left: 0;
  }
}

.timeline {
  position: fixed;
  top: 50%;
  left: $mobile-padding;
  transform: translateY(-50%);
  font-family: $headline;
  font-size: 2.4em;
  line-height: 1;
  display: none;
  pointer-events: none;
  
  @include desktop {
    left: 50%;
  }
  
  [data-layout="grid"] & {
    display: block;
  }

  .timeline-locked & {
    position: absolute;
    bottom: 50vh;
    top: auto;
    transform: translateY(50%);
  }
  
  &-year {
    display: none;
    
    &.is-active {
      display: block;
    }
  }
}

.timeline-locked {
  position: relative;
}

.main--grid {
  margin-top: 51px;
  display: none;
  
  @include phone {
    margin-top: 40px;
  }
  
  &.is-active {
    display: block;
  }
}

.layout-controls {
  display: flex;
  margin-left: auto;
  
  @include phone {
    display: none;
  }
  
  &-inner {
    display: flex;
  }
}

.layout-control {
  @extend %clickable;
  text-align: center;
  opacity: .22;
  margin-right: 8px;
  
  &:last-child {
    margin-right: 0;
  }
  
  &.is-active {
    opacity: 1;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @supports (display: grid) {
    overflow: initial;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 50px;
  }
  
  @include desktop {
    grid-gap: 100px;
  }
  
  @include phone {
    display: block;
  }
}

.product-preview {
  position: relative;
  color: transparent;
  pointer-events: none;
  margin-right: 100px;
  margin-bottom: 100px;
  width: calc(25% - 75px);
  padding-bottom: calc(25% - 75px);
  
  &:nth-child(4n) {
    margin-right: 0;
  }
  
  @supports (display: grid) {
    padding-bottom: 100%;
    margin: 0;
    width: auto;
  }
  
  @include touch {
    padding: 0;
    color: $color !important;
    pointer-events: auto;
  }

  @include phone {
    margin: 0 $mobile-padding 50px;
    display: block;
  }
  
  &-inner,
  &-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    @include touch {
      position: static;
      height: auto;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    @include touch {
      justify-content: flex-start;
      text-align: left;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &-image {
    @include touch {
      flex-basis: 100%;
    }

    img {
      pointer-events: auto;
      vertical-align: middle;
      
      &.landscape {
        height: auto;
        top: 50%;
        transform: translateY(-50%);
      }

      &.portrait {
        width: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      @include touch {
        transform: none !important;
        width: 100% !important;
        max-height: calc(100vh - #{2*$mobile-padding}) !important;
        height: auto;
        margin: 0 auto;
        object-fit: contain;
        font-family: "object-fit: contain";
      }
    }
  }

  &-name {
    @include touch {
      flex-basis: 100%;
    }
  }

  &-description {
    @include touch {
      &:after {
        content: ", ";
        margin-right: .25em;
      }
    }
  }

  &-designer,
  &-category {
    display: none;
  }

  &.is-highlighted,
  &:hover {
    color: $color;
    
    .product-preview {
      &-image img {
        opacity: 0;
        
        @include touch {
          opacity: 1;
        }
      }
    }
  }
}

.table-header {
  display: none;
  border-bottom: none;
  
  [data-layout="table"] & {
    display: flex;
  }
}

.table-header-cell {
  height: $cell-size;
  
  &--sortable {
    @extend %clickable;
  }
  
  &:first-child {
    border-top: none;
    margin-left: 2px;
    padding: 0;
  }
}

[data-layout="table"] {
  .grid {
    display: block;
  }

  .column-image,
  .column-year {
    width: 10%;
  }

  .column-name,
  .column-description,
  .column-category,
  .column-designer {
    width: calc(80% / 4);
    padding-right: .2em;
  }

  .product-preview {
    pointer-events: auto;
    display: block;
    padding: 0;
    width: auto;
    margin: 0;
    
    &:not(:last-child) .product-preview-inner {
      border-bottom: none;
    }

    &-image img,
    &-inner,
    &-year {
      position: static;
    }

    &-inner {
      flex-direction: row;
      justify-content: stretch;
      align-items: flex-start;
      color: $color;
      text-align: left;
      border-top: 1px solid;
      padding: 6px 0;
    }

    &-image img {
      width: $cell-image-width !important;
      height: $cell-image-height !important;
      object-fit: contain;
      object-position: left top;
      font-family: "object-fit: contain, object-position: left top";
      transform: none !important;
      opacity: 1 !important;
      mix-blend-mode: multiply;
    }

    &-name,
    &-description,
    &-category,
    &-designer,
    &-year {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.is-highlighted,
    &:hover {      
      .product-preview-description {
        color: $color;
      }
    }
  }
}

.text_slideshow {
  margin: calc(50vh - #{$padding}) 0 0;
  
  .container {
    display: flex;
    padding-top: $padding;
    padding-bottom: $padding;
    margin-bottom: $bottom-space;
    
    @include portrait {
      display: block;
      margin-bottom: $mobile-bottom-space;
    }
  }

  &-text {
    margin-left: 2px;
    padding-left: 50%;
    
    @include phone {
      margin-left: 0;
      padding-left: 0;
    }
    
    p {
      text-indent: 40px;
      margin-bottom: 0;
      
      &:first-child {
        text-indent: 0;
      }
    }
  }
}

.big_text {
  margin-top: 100px;
  
  @include phone {
    margin-top: 40px;
  }
  
  .container {
    padding-left: 50%;
    padding-top: $padding;
    padding-bottom: $padding;
    
    @include portrait {
      padding-left: $mobile-padding;
    }
    
    p {
      @extend h2;
      color: inherit;
    }
  }
}

.footer {  
  .container {
    margin-top: $padding;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    
    .menu-link--language {
      margin-right: -$menu-horizontal-padding;
    
      @include phone {
        margin-left: -$mobile-menu-horizontal-padding;
        margin-right: 0;
      }
    }
    
    @include portrait {
      justify-content: flex-start;
      min-height: 0;
    }

    @include phone {
      display: block;
    }
  }

  &-copyright {
    margin-left: -$menu-horizontal-padding;
    
    @include phone {
      margin-left: -$mobile-menu-horizontal-padding;
    }

    p {
      @extend .menu-link;
    }

    @include portrait {
      display: none;
    }
  }

  &-menu {
    display: flex;
    position: absolute;
    left: 50%;
    margin-left: -$menu-horizontal-padding;
    padding-left: 2px;
    margin-bottom: 10px;
    
    @include portrait {
      position: static;
    }

    @include phone {
      margin-left: -$mobile-menu-horizontal-padding;
    }
  }

  .icon-external {
    vertical-align: top;
    width: 7px;
    height: 7px;
  }

  p {
    margin-bottom: 0;
  }
}

.products-slider,
.product-gallery {
  height: 100%;
  display: flex;
}

.product,
.product-image {
  &:first-child,
  &:last-child {    
    &.is-bouncing-in {
      transition: transform 200ms ease-out;
    }
    
    &.is-bouncing-out {
      transition: transform 200ms ease-out;
    }
  }
}

.products {
  position: absolute;
  right: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: hsla(0,0%,0%,0);
  z-index: 2;
  background: $bg;
  opacity: 0;
  pointer-events: none;
  
  &.is-active {
    opacity: 1;
    pointer-events: auto;
    right: 0;
  }

  &-gallery {
    height: 100%;
  }
  
  &-slider {
    position: relative;
    flex-direction: column;
  }

  &-nav {
    @include touch {
      display: none;
    }

    &-left,
    &-right,
    &-up,
    &-down {
      @extend %clickable;
      position: absolute;
      z-index: 1;
    }

    &-left,
    &-right {
      left: 0;
      width: 50%;
      top: 0;
      height: 100%;
    }

    &-left {
      cursor: w-resize;
      cursor: url("../images/cursor-w-resize.png"), url("../images/cursor-w-resize.svg") 9 9, w-resize;
      cursor: -webkit-image-set(url("../images/cursor-w-resize.png") 1x, url("../images/cursor-w-resize@2x.png") 2x) 9 9, w-resize;
    }

    &-right {
      left: 50%;
      cursor: e-resize;
      cursor: url("../images/cursor-e-resize.png"), url("../images/cursor-e-resize.svg") 9 9, e-resize;
      cursor: -webkit-image-set(url("../images/cursor-e-resize.png") 1x, url("../images/cursor-e-resize@2x.png") 2x) 9 9, e-resize;
    }

    &-up,
    &-down {
      width: 60%;
      height: 35%;
      left: 20%;
      top: 0;
    }

    &-up {
      cursor: n-resize;
      cursor: url("../images/cursor-n-resize.png"), url("../images/cursor-n-resize.svg") 9 9, n-resize;
      cursor: -webkit-image-set(url("../images/cursor-n-resize.png") 1x, url("../images/cursor-n-resize@2x.png") 2x) 9 9, n-resize;
    }

    &-down {
      top: 65%;
      cursor: s-resize;
      cursor: url("../images/cursor-s-resize.png"), url("../images/cursor-s-resize.svg") 9 9, s-resize;
      cursor: -webkit-image-set(url("../images/cursor-s-resize.png") 1x, url("../images/cursor-s-resize@2x.png") 2x) 9 9, s-resize;
    }
  }
}

.product {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  touch-action: manipulation;
  
  &-gallery {
    width: 100%;
  }
  
  &-image {
    width: 100%;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include phone {
      height: 100%;
    }

    &.landscape img {
      height: 70%;
      
      @include phone {
        height: 80%;
      }
    }

    &.portrait img {
      height: 80%;
      
      @include phone {
        height: 67%;
      }
    }
    
    img {
      width: 65%;
      object-fit: contain;
      font-family: "object-fit: contain";
      
      @include phone {
        width: calc(100% - #{$mobile-padding*4});
      }
    }
  }
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: $padding/3*2 $padding;
  z-index: 1;
  
  @include phone {
    padding: $mobile-padding;
  }
}

.product-meta {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;

  &.is-active {
    display: block;
  }

  .products.is-active &.is-active {    
    @include touch {
      pointer-events: none;
    }
  }

  &-text,
  &-detail,
  &-shop,
  &-pagination {
    position: absolute;
    padding: $padding/3*2 $padding;
  
    @include phone {
      padding: $mobile-padding;
    }
  }

  &-text {
    left: 0;
    top: 0;
  }
  
  &-pagination {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    
    span {
      margin: 0 .1em;
      
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-detail {
    left: 0;
    bottom: 0;
  }

  &-shop {
    right: 0;
    bottom: 0;
    z-index: 1;
    
    @include touch {
      pointer-events: auto;
    }
    
    &:link {
      color: $blue;
    }

    &:visited {
      color: $purple;
    }
  }
}