.icon-burger {
  width: 21px;
  height: 21px; }

.icon-close {
  width: 21px;
  height: 21px; }

.icon-external {
  width: 12px;
  height: 12px; }

.icon-grid {
  width: 37px;
  height: 37px; }

.icon-table {
  width: 37px;
  height: 37px; }

@font-face {
  font-family: "Futura Mono";
  font-weight: bold;
  src: url("../fonts/Futura Mono.woff") format("woff"), url("../fonts/Futura Mono.woff2") format("woff2"); }

@font-face {
  font-family: "Lausanne";
  src: url("../fonts/Lausanne-Regular.woff") format("woff"), url("../fonts/Lausanne-Regular.woff2") format("woff2"); }

@font-face {
  font-family: "Lausanne";
  font-style: italic;
  src: url("../fonts/Lausanne-Italic.woff") format("woff"), url("../fonts/Lausanne-Italic.woff2") format("woff2"); }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.menu-link, .footer-copyright p, .layout-control, .table-header-cell--sortable, .products-nav-left, .products-nav-right, .products-nav-up, .products-nav-down {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5; }

body {
  background: #ebeae5;
  font-family: "Lausanne", sans-serif;
  font-size: 16px;
  line-height: 1.35;
  color: #1a1919;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, blockquote,
h2,
.big_text .container p {
  font-family: "Futura Mono", sans-serif;
  font-size: 34px;
  line-height: 1.1; }
  @media screen and (max-width: 767px) {
    h1, blockquote,
    h2,
    .big_text .container p {
      font-size: 6vw; } }

p {
  margin-bottom: 1em; }

a {
  color: inherit;
  text-decoration: none; }

blockquote {
  color: inherit; }

.container {
  padding: 0 50px; }
  @media screen and (max-width: 767px) {
    .container {
      padding-left: 20px;
      padding-right: 20px; } }

.image-ratio {
  position: relative; }
  .image-ratio img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

svg {
  fill: currentColor;
  vertical-align: middle; }

.header {
  padding: 25px 0; }
  .header .container {
    display: flex;
    position: relative; }

.logo {
  margin-left: -20px; }
  @media screen and (max-width: 767px) {
    .logo {
      margin-left: -10px; } }

.menu {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -20px;
  display: flex; }
  @media screen and (max-width: 767px) {
    .menu {
      margin-left: -10px; } }

.menu-link, .footer-copyright p {
  display: block;
  padding: 0 20px;
  text-decoration: none;
  -webkit-tap-highlight-color: black; }
  .header .menu-link, .header .footer-copyright p, .footer-copyright .header p {
    font-family: "Futura Mono", sans-serif;
    font-size: 2.4em;
    line-height: 1; }
  .menu-link:hover, .footer-copyright p:hover, .menu-link.is-active, .footer-copyright p.is-active {
    font-style: italic; }
  @media screen and (max-width: 767px) {
    .menu-link, .footer-copyright p {
      padding-left: 10px;
      padding-right: 10px; } }
  .menu-link + .menu-link, .footer-copyright p + .menu-link, .footer-copyright .menu-link + p, .footer-copyright p + p {
    padding-left: 0; }

.timeline {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  font-family: "Futura Mono", sans-serif;
  font-size: 2.4em;
  line-height: 1;
  display: none;
  pointer-events: none; }
  @media screen and (min-width: 1025px) {
    .timeline {
      left: 50%; } }
  [data-layout="grid"] .timeline {
    display: block; }
  .timeline-locked .timeline {
    position: absolute;
    bottom: 50vh;
    top: auto;
    transform: translateY(50%); }
  .timeline-year {
    display: none; }
    .timeline-year.is-active {
      display: block; }

.timeline-locked {
  position: relative; }

.main--grid {
  margin-top: 51px;
  display: none; }
  @media screen and (max-width: 767px) {
    .main--grid {
      margin-top: 40px; } }
  .main--grid.is-active {
    display: block; }

.layout-controls {
  display: flex;
  margin-left: auto; }
  @media screen and (max-width: 767px) {
    .layout-controls {
      display: none; } }
  .layout-controls-inner {
    display: flex; }

.layout-control {
  text-align: center;
  opacity: .22;
  margin-right: 8px; }
  .layout-control:last-child {
    margin-right: 0; }
  .layout-control.is-active {
    opacity: 1; }

.grid {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden; }
  @supports (display: grid) {
    .grid {
      overflow: initial;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
      grid-gap: 50px; } }
  @media screen and (min-width: 1025px) {
    .grid {
      grid-gap: 100px; } }
  @media screen and (max-width: 767px) {
    .grid {
      display: block; } }

.product-preview {
  position: relative;
  color: transparent;
  pointer-events: none;
  margin-right: 100px;
  margin-bottom: 100px;
  width: calc(25% - 75px);
  padding-bottom: calc(25% - 75px); }
  .product-preview:nth-child(4n) {
    margin-right: 0; }
  @supports (display: grid) {
    .product-preview {
      padding-bottom: 100%;
      margin: 0;
      width: auto; } }
  @media screen and (any-pointer: coarse) {
    .product-preview {
      padding: 0;
      color: #1a1919 !important;
      pointer-events: auto; } }
  @media screen and (max-width: 767px) {
    .product-preview {
      margin: 0 20px 50px;
      display: block; } }
  .product-preview-inner,
  .product-preview-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    @media screen and (any-pointer: coarse) {
      .product-preview-inner,
      .product-preview-image img {
        position: static;
        height: auto; } }
  .product-preview-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    @media screen and (any-pointer: coarse) {
      .product-preview-inner {
        justify-content: flex-start;
        text-align: left;
        flex-direction: row;
        flex-wrap: wrap; } }
  @media screen and (any-pointer: coarse) {
    .product-preview-image {
      flex-basis: 100%; } }
  .product-preview-image img {
    pointer-events: auto;
    vertical-align: middle; }
    .product-preview-image img.landscape {
      height: auto;
      top: 50%;
      transform: translateY(-50%); }
    .product-preview-image img.portrait {
      width: auto;
      left: 50%;
      transform: translateX(-50%); }
    @media screen and (any-pointer: coarse) {
      .product-preview-image img {
        transform: none !important;
        width: 100% !important;
        max-height: calc(100vh - 40px) !important;
        height: auto;
        margin: 0 auto;
        object-fit: contain;
        font-family: "object-fit: contain"; } }
  @media screen and (any-pointer: coarse) {
    .product-preview-name {
      flex-basis: 100%; } }
  @media screen and (any-pointer: coarse) {
    .product-preview-description:after {
      content: ", ";
      margin-right: .25em; } }
  .product-preview-designer, .product-preview-category {
    display: none; }
  .product-preview.is-highlighted, .product-preview:hover {
    color: #1a1919; }
    .product-preview.is-highlighted .product-preview-image img, .product-preview:hover .product-preview-image img {
      opacity: 0; }
      @media screen and (any-pointer: coarse) {
        .product-preview.is-highlighted .product-preview-image img, .product-preview:hover .product-preview-image img {
          opacity: 1; } }

.table-header {
  display: none;
  border-bottom: none; }
  [data-layout="table"] .table-header {
    display: flex; }

.table-header-cell {
  height: 60px; }
  .table-header-cell:first-child {
    border-top: none;
    margin-left: 2px;
    padding: 0; }

[data-layout="table"] .grid {
  display: block; }

[data-layout="table"] .column-image,
[data-layout="table"] .column-year {
  width: 10%; }

[data-layout="table"] .column-name,
[data-layout="table"] .column-description,
[data-layout="table"] .column-category,
[data-layout="table"] .column-designer {
  width: calc(80% / 4);
  padding-right: .2em; }

[data-layout="table"] .product-preview {
  pointer-events: auto;
  display: block;
  padding: 0;
  width: auto;
  margin: 0; }
  [data-layout="table"] .product-preview:not(:last-child) .product-preview-inner {
    border-bottom: none; }
  [data-layout="table"] .product-preview-image img, [data-layout="table"] .product-preview-inner, [data-layout="table"] .product-preview-year {
    position: static; }
  [data-layout="table"] .product-preview-inner {
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    color: #1a1919;
    text-align: left;
    border-top: 1px solid;
    padding: 6px 0; }
  [data-layout="table"] .product-preview-image img {
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
    object-position: left top;
    font-family: "object-fit: contain, object-position: left top";
    transform: none !important;
    opacity: 1 !important;
    mix-blend-mode: multiply; }
  [data-layout="table"] .product-preview-name, [data-layout="table"] .product-preview-description, [data-layout="table"] .product-preview-category, [data-layout="table"] .product-preview-designer, [data-layout="table"] .product-preview-year {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  [data-layout="table"] .product-preview.is-highlighted .product-preview-description, [data-layout="table"] .product-preview:hover .product-preview-description {
    color: #1a1919; }

.text_slideshow {
  margin: calc(50vh - 50px) 0 0; }
  .text_slideshow .container {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 140px; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .text_slideshow .container {
        display: block;
        margin-bottom: 40px; } }
  .text_slideshow-text {
    margin-left: 2px;
    padding-left: 50%; }
    @media screen and (max-width: 767px) {
      .text_slideshow-text {
        margin-left: 0;
        padding-left: 0; } }
    .text_slideshow-text p {
      text-indent: 40px;
      margin-bottom: 0; }
      .text_slideshow-text p:first-child {
        text-indent: 0; }

.big_text {
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    .big_text {
      margin-top: 40px; } }
  .big_text .container {
    padding-left: 50%;
    padding-top: 50px;
    padding-bottom: 50px; }
    @media screen and (max-aspect-ratio: 1 / 1) {
      .big_text .container {
        padding-left: 20px; } }
    .big_text .container p {
      color: inherit; }

.footer .container {
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between; }
  .footer .container .menu-link--language {
    margin-right: -20px; }
    @media screen and (max-width: 767px) {
      .footer .container .menu-link--language {
        margin-left: -10px;
        margin-right: 0; } }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .footer .container {
      justify-content: flex-start;
      min-height: 0; } }
  @media screen and (max-width: 767px) {
    .footer .container {
      display: block; } }

.footer-copyright {
  margin-left: -20px; }
  @media screen and (max-width: 767px) {
    .footer-copyright {
      margin-left: -10px; } }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .footer-copyright {
      display: none; } }

.footer-menu {
  display: flex;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  padding-left: 2px;
  margin-bottom: 10px; }
  @media screen and (max-aspect-ratio: 1 / 1) {
    .footer-menu {
      position: static; } }
  @media screen and (max-width: 767px) {
    .footer-menu {
      margin-left: -10px; } }

.footer .icon-external {
  vertical-align: top;
  width: 7px;
  height: 7px; }

.footer p {
  margin-bottom: 0; }

.products-slider,
.product-gallery {
  height: 100%;
  display: flex; }

.product:first-child.is-bouncing-in, .product:last-child.is-bouncing-in,
.product-image:first-child.is-bouncing-in,
.product-image:last-child.is-bouncing-in {
  transition: transform 200ms ease-out; }

.product:first-child.is-bouncing-out, .product:last-child.is-bouncing-out,
.product-image:first-child.is-bouncing-out,
.product-image:last-child.is-bouncing-out {
  transition: transform 200ms ease-out; }

.products {
  position: absolute;
  right: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 2;
  background: #ebeae5;
  opacity: 0;
  pointer-events: none; }
  .products.is-active {
    opacity: 1;
    pointer-events: auto;
    right: 0; }
  .products-gallery {
    height: 100%; }
  .products-slider {
    position: relative;
    flex-direction: column; }
  @media screen and (any-pointer: coarse) {
    .products-nav {
      display: none; } }
  .products-nav-left, .products-nav-right, .products-nav-up, .products-nav-down {
    position: absolute;
    z-index: 1; }
  .products-nav-left, .products-nav-right {
    left: 0;
    width: 50%;
    top: 0;
    height: 100%; }
  .products-nav-left {
    cursor: w-resize;
    cursor: url("../images/cursor-w-resize.png"), url("../images/cursor-w-resize.svg") 9 9, w-resize;
    cursor: -webkit-image-set(url("../images/cursor-w-resize.png") 1x, url("../images/cursor-w-resize@2x.png") 2x) 9 9, w-resize; }
  .products-nav-right {
    left: 50%;
    cursor: e-resize;
    cursor: url("../images/cursor-e-resize.png"), url("../images/cursor-e-resize.svg") 9 9, e-resize;
    cursor: -webkit-image-set(url("../images/cursor-e-resize.png") 1x, url("../images/cursor-e-resize@2x.png") 2x) 9 9, e-resize; }
  .products-nav-up, .products-nav-down {
    width: 60%;
    height: 35%;
    left: 20%;
    top: 0; }
  .products-nav-up {
    cursor: n-resize;
    cursor: url("../images/cursor-n-resize.png"), url("../images/cursor-n-resize.svg") 9 9, n-resize;
    cursor: -webkit-image-set(url("../images/cursor-n-resize.png") 1x, url("../images/cursor-n-resize@2x.png") 2x) 9 9, n-resize; }
  .products-nav-down {
    top: 65%;
    cursor: s-resize;
    cursor: url("../images/cursor-s-resize.png"), url("../images/cursor-s-resize.svg") 9 9, s-resize;
    cursor: -webkit-image-set(url("../images/cursor-s-resize.png") 1x, url("../images/cursor-s-resize@2x.png") 2x) 9 9, s-resize; }

.product {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  touch-action: manipulation; }
  .product-gallery {
    width: 100%; }
  .product-image {
    width: 100%;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .product-image {
        height: 100%; } }
    .product-image.landscape img {
      height: 70%; }
      @media screen and (max-width: 767px) {
        .product-image.landscape img {
          height: 80%; } }
    .product-image.portrait img {
      height: 80%; }
      @media screen and (max-width: 767px) {
        .product-image.portrait img {
          height: 67%; } }
    .product-image img {
      width: 65%;
      object-fit: contain;
      font-family: "object-fit: contain"; }
      @media screen and (max-width: 767px) {
        .product-image img {
          width: calc(100% - 80px); } }

.close-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 33.33333px 50px;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .close-button {
      padding: 20px; } }

.product-meta {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }
  .product-meta.is-active {
    display: block; }
  @media screen and (any-pointer: coarse) {
    .products.is-active .product-meta.is-active {
      pointer-events: none; } }
  .product-meta-text, .product-meta-detail, .product-meta-shop, .product-meta-pagination {
    position: absolute;
    padding: 33.33333px 50px; }
    @media screen and (max-width: 767px) {
      .product-meta-text, .product-meta-detail, .product-meta-shop, .product-meta-pagination {
        padding: 20px; } }
  .product-meta-text {
    left: 0;
    top: 0; }
  .product-meta-pagination {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex; }
    .product-meta-pagination span {
      margin: 0 .1em; }
      .product-meta-pagination span:first-child {
        margin-left: 0; }
  .product-meta-detail {
    left: 0;
    bottom: 0; }
  .product-meta-shop {
    right: 0;
    bottom: 0;
    z-index: 1; }
    @media screen and (any-pointer: coarse) {
      .product-meta-shop {
        pointer-events: auto; } }
    .product-meta-shop:link {
      color: #0000cc; }
    .product-meta-shop:visited {
      color: #4400cc; }
